import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { useRecoilValue, useRecoilState } from "recoil"
import date from 'date-and-time'
import { Navbar, TestMessage, InitData, MessagesTable } from '../components'
import * as Atoms from '@/atoms'
import { useRealtimeData } from '@/hooks'
import { BsFillTrashFill } from 'react-icons/bs'
import { useEffect } from 'react'

export default function Home() {
  const supabaseClient = useSupabaseClient()
  const { messages, loadingInitial } = useRealtimeData()
  const [open, setOpen] = useRecoilState(Atoms.testMsgModal)
  const [userAtom, setUserAtom] = useRecoilState(Atoms.userState)

  const deleteMsg = async (id: string) => {
    const timestamp = new Date();
    try {
      await supabaseClient
        .from('messages')
        .update({ 
          status: 'deleted',
          status_desc: 'User deleted on web',
          is_complete: true,
          updated_at: timestamp.toISOString().replace('T', ' ').replace('Z', '000')
        })
        .eq('id', id)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <>
      <Navbar />
      <TestMessage />
      <main className='container'>
        {
          loadingInitial || !userAtom.id ?
          <article aria-busy='true'></article> :
          <article>
            <nav>
              <ul>
                <li><h1>Queued Messages</h1></li>
              </ul>
              <ul>
                <li><button onClick={() => setOpen((old) => !old)}>Test Add Message</button></li>
              </ul>
            </nav>
            <MessagesTable loading={loadingInitial ?? true} messages={messages} deleteFn={deleteMsg} />
          </article>

        }
      </main>
    </>
  )
}
